import React from 'react';

const Logo = () => {
  return (
    <div className="fest-nav-logo">
      <svg width="100%" height="100%" viewBox="0 0 758 475" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1,0,0,1,-34.6888,-27.8054)">
          <g transform="matrix(4.16667,0,0,4.16667,129.639,350.285)">
              <path d="M0,-43.141L30.528,-43.141L30.528,-41.277L0,-41.277L0,34.254L-12.469,34.254L-12.469,-41.277L-22.788,-41.277L-22.788,-43.141L-12.469,-43.141C-12.326,-68.223 2.007,-77.395 15.909,-77.395C27.232,-77.395 34.971,-72.665 42.137,-63.923L33.825,-56.613C31.675,-63.923 29.668,-75.532 16.339,-75.532C3.154,-75.532 0,-61.629 0,-43.141"
              />
          </g>
          <g transform="matrix(4.16667,0,0,4.16667,749.058,413.587)">
              <path d="M0,-56.47L0,2.58C0,9.459 -0.143,15.765 1.863,19.062L-10.606,19.062C-12.612,17.055 -12.469,9.459 -12.469,2.436L-12.469,-56.47L-42.99,-56.47L-42.99,-58.334L-12.469,-58.334L-12.469,-68.366L0,-75.532L0,-58.334L10.326,-58.334L10.326,-56.47L0,-56.47Z"
              />
          </g>
          <g transform="matrix(4.16667,0,0,4.16667,576.946,384.92)">
              <path d="M0,-13.472C-9.889,-18.919 -17.915,-21.069 -17.915,-29.094C-17.915,-33.537 -12.9,-39.414 -4.873,-39.414C5.733,-39.414 16.195,-28.522 18.345,-24.078L18.345,-33.967C14.046,-36.548 4.3,-41.564 -5.733,-41.564C-18.776,-41.564 -29.812,-34.111 -29.812,-24.078C-29.812,-13.472 -18.203,-9.602 -6.736,-3.726C3.009,1.29 10.892,5.304 10.892,12.899C10.892,20.198 4.971,25.537 -5.402,25.942C-16.151,25.942 -22.337,18.623 -25.897,7.453C-35.283,-18.924 -44.668,7.453 -44.668,7.453C-48.251,18.695 -54.414,25.942 -65.164,25.942C-75.626,25.942 -82.506,19.922 -86.089,10.893C-90.818,-6.879 -79.066,-14.619 -65.88,-14.619C-51.548,-14.619 -34.635,-10.749 -34.635,-20.639C-34.635,-29.955 -46.961,-41.564 -65.02,-41.564C-85.372,-41.564 -101.424,-26.372 -101.424,-6.593C-101.424,12.899 -85.372,28.092 -65.02,28.092C-52.269,28.092 -41.347,21.083 -35.283,10.815C-29.249,21.032 -18.403,28.017 -5.732,28.085L-5.733,28.092C-5.688,28.092 -5.644,28.09 -5.599,28.09C-5.581,28.09 -5.563,28.092 -5.545,28.092L-5.545,28.089C10.412,28.034 22.215,21.027 22.215,10.463C22.215,-0.86 10.892,-7.452 0,-13.472M-88.812,-5.302C-88.812,-23.792 -75.913,-38.984 -61.151,-38.984C-49.541,-38.984 -43.379,-31.675 -43.379,-23.792C-43.379,-14.619 -52.694,-16.913 -64.017,-16.913C-78.779,-16.913 -87.379,-10.319 -88.525,0.143C-88.669,-1.72 -88.812,-3.44 -88.812,-5.302"
              />
          </g>
      </g>
  </svg>
    </div>
  );
}
export default Logo;
