import React from 'react';

const Instagram = () => {
  return (
      <svg width="100%" height="100%" viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g transform="matrix(1,0,0,1,-2157.2,-3066.01)">
              <g>
                  <g transform="matrix(5.55556,0,0,5.55556,2213.31,3133.33)">
                      <path d="M0,-12.118L-8.079,-12.118C-8.615,-12.118 -9.128,-11.905 -9.507,-11.526C-9.886,-11.148 -10.099,-10.634 -10.099,-10.098L-10.099,-2.019C-10.099,-1.484 -9.886,-0.97 -9.507,-0.591C-9.128,-0.212 -8.615,0 -8.079,0L0,0C0.536,0 1.049,-0.212 1.428,-0.591C1.807,-0.97 2.02,-1.484 2.02,-2.019L2.02,-10.098C2.02,-10.634 1.807,-11.148 1.428,-11.526C1.049,-11.905 0.536,-12.118 0,-12.118M1.01,-2.019C1.01,-1.751 0.903,-1.495 0.714,-1.305C0.525,-1.116 0.268,-1.009 0,-1.009L-8.079,-1.009C-8.347,-1.009 -8.604,-1.116 -8.793,-1.305C-8.983,-1.495 -9.089,-1.751 -9.089,-2.019L-9.089,-10.098C-9.089,-10.366 -8.983,-10.623 -8.793,-10.812C-8.604,-11.002 -8.347,-11.108 -8.079,-11.108L0,-11.108C0.268,-11.108 0.525,-11.002 0.714,-10.812C0.903,-10.623 1.01,-10.366 1.01,-10.098L1.01,-2.019Z"
                      />
                  </g>
                  <g transform="matrix(5.55556,0,0,5.55556,2190.86,3116.5)">
                      <path d="M0,-6.059C-0.803,-6.059 -1.574,-5.74 -2.142,-5.172C-2.71,-4.603 -3.03,-3.833 -3.03,-3.029C-3.03,-2.226 -2.71,-1.455 -2.142,-0.887C-1.574,-0.319 -0.803,0 0,0C0.803,0 1.574,-0.319 2.142,-0.887C2.71,-1.455 3.03,-2.226 3.03,-3.029C3.03,-3.833 2.71,-4.603 2.142,-5.172C1.574,-5.74 0.803,-6.059 0,-6.059M0,-1.01C-0.536,-1.01 -1.049,-1.223 -1.428,-1.601C-1.807,-1.98 -2.02,-2.494 -2.02,-3.029C-2.02,-3.565 -1.807,-4.079 -1.428,-4.458C-1.049,-4.836 -0.536,-5.049 0,-5.049C0.536,-5.049 1.049,-4.836 1.428,-4.458C1.807,-4.079 2.02,-3.565 2.02,-3.029C2.02,-2.494 1.807,-1.98 1.428,-1.601C1.049,-1.223 0.536,-1.01 0,-1.01"
                      />
                  </g>
                  <g transform="matrix(0,-5.55556,-5.55556,0,2209.1,3077.23)">
                      <path d="M-0.757,-0.757C-1.176,-0.757 -1.515,-0.419 -1.515,-0C-1.515,0.419 -1.176,0.757 -0.757,0.757C-0.339,0.757 -0,0.419 -0,-0C-0,-0.419 -0.339,-0.757 -0.757,-0.757"
                      />
                  </g>
              </g>
          </g>
      </svg>
  );
}
export default Instagram;
