import React from 'react'
import Logo from './Logo'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      newTab: null
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState({
      active: !this.state.active,
    })
  }

  openLink = () => {
    if (!this.state.newtab || this.state.newtab.closed) {
      this.setState({
        newTab: window.open("https://shop.feststore.be", "fest-webshop")
      })
      console.log(this.state.newTab)
    } else {
      this.state.newtab.focus();
    }
  }

  render() {
    return (
<nav className="navbar navbar-expand-lg navbar-dark bg-dark container-padding">
  <div className='fest-nav-logo-mobile'>
    <Link to="/" title="Home" activeStyle={{ visibility: "hidden" }}>
      <Logo />
    </Link>
  </div>
  <button className="navbar-toggler"
    type="button" data-toggle="collapse"
    data-target="#navbar" 
    aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"
    onClick={this.toggleHamburger}
    >
    <span className="navbar-toggler-icon">
      {this.state.active ?
      <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" viewBox="0 0 18 17">
        <path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z"></path>
      </svg> : 
      <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" viewBox="0 0 18 16">
        <path d="M1 .5a.5.5 0 100 1h15.71a.5.5 0 000-1H1zM.5 8a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1A.5.5 0 01.5 8zm0 7a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1a.5.5 0 01-.5-.5z"></path>
      </svg>
      }
    </span>
  </button>

  <div className={(this.state.active ? '' : 'collapse') + ' navbar-collapse justify-content-md-center'} id="navbar">
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/about" className="nav-link" title="About">About</Link>
      </li>
      <li className="nav-item">
        <div className="nav-link" title="webshop" onClick={this.openLink}>
          Webshop
        </div>
      </li>
      <li className="nav-item fest-nav-logo-item">
        <Link to="/" className="nav-link" title="Home" activeStyle={{ visibility: "hidden" }}>
          {/* <img className="fest-nav-logo" src={logoLight} alt="Life's a Fest" title="Life's a Fest"/> */}
          <Logo />
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/events" className="nav-link" title="About">Events</Link>
      </li>
      <li className="nav-item">
        <Link to="/contact" className="nav-link" title="Contact">Contact</Link>
      </li>
      {/* <li className="nav-item">
        <a className="nav-link" target="_blank" rel="noopener noreferrer"
             href={this.props.contactgegevens.facebook} title="facebook">
               <img src={facebook} alt="facebook" height="27" className="facebook"/>
        </a>
      </li> */}
    </ul>
  </div>
</nav>
    )
  }
}

export default Navbar
