import * as React from "react";
import { Helmet } from "react-helmet";
import "./all.sass";
import { withPrefix } from "gatsby";
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const TemplateWrapper = ({ children, pageTitle }) => {
  const { title, description } = { title: 'Fest', description: 'Het leven is een feest, voor iedereen. Come in en vind jouw ideale outfit voor elke gelegenheid and celebrate!' };

  return (
    <div>
      <Helmet
        defaultTitle={title}
        title={pageTitle}
        titleTemplate="%s">
        <html lang="en" />
        {/* <title>{title}</title> */}
        <meta name="description" content={description} />
        <link rel="canonical" href="https://www.feststore.be/" />
        <link rel="icon" href={`${withPrefix("/")}img/favicon.ico`} sizes="any" />
        <link rel="icon" href={`${withPrefix("/")}img/icon.svg`} type="image/svg+xml"></link>
        <link rel="apple-touch-icon" href={`${withPrefix("/")}img/apple-touch-icon.png`} sizes="180x180"/>
        <link rel="icon" type="image/png" href={`${withPrefix("/")}img/favicon-32x32.png`} sizes="32x32" />
        <link rel="icon" type="image/png" href={`${withPrefix("/")}img/favicon-16x16.png`} sizes="16x16"/>

        <link rel="mask-icon" href={`${withPrefix("/")}img/safari-pinned-tab.svg`} color="#ff4400" />
        <meta name="theme-color" content="#fff" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta property="og:type" content="website" />
        <meta property="og:locale" content="be_NL" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
        <meta property="og:site_name" content={title} />
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="22d7f1a1-0064-4a42-82ba-c11a9e205d70" async type="text/javascript"></script>

      </Helmet>
      <script id="CookieDeclaration" src="https://consent.cookiebot.com/22d7f1a1-0064-4a42-82ba-c11a9e205d70/cd.js" type="text/javascript"></script>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
