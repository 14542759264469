import React from 'react';

const Facebook = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 35 68" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1,0,0,1,-2252.38,-3066.01)">
            <g transform="matrix(5.55556,0,0,5.55556,2275.06,3065.99)">
                <path d="M0,12.115L0,6.587L1.848,6.587C1.941,5.872 2.033,5.167 2.127,4.441L0.003,4.441C-0.001,4.399 -0.008,4.368 -0.008,4.336C-0.008,3.883 -0.01,3.43 -0.006,2.978C-0.005,2.869 0.004,2.757 0.03,2.651C0.129,2.239 0.381,2.049 0.891,2.017C1.169,1.999 1.449,2.01 1.728,2.008C1.88,2.007 2.032,2.008 2.193,2.008L2.193,0.085C1.997,0.067 1.797,0.039 1.597,0.031C1.196,0.016 0.794,-0.006 0.393,0.006C-0.369,0.029 -1.052,0.261 -1.573,0.85C-1.929,1.251 -2.127,1.73 -2.184,2.258C-2.225,2.647 -2.224,3.041 -2.233,3.434C-2.241,3.734 -2.234,4.034 -2.234,4.334C-2.234,4.367 -2.239,4.399 -2.242,4.439L-4.083,4.439L-4.083,6.588L-2.244,6.588L-2.244,12.121C-2.431,12.121 0.037,12.117 0,12.115"
                />
            </g>
        </g>
    </svg>
  );
}
export default Facebook;
