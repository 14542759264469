import React from 'react'
import { Link } from 'gatsby'

// import insta from '../img/instagram.svg'
// import fb from '../img/facebook.svg'
import Instagram from './Instagram'
import Facebook from './Facebook'

const Navbar = class extends React.Component {

  render() {
    return (
      <footer className="container-xxl container-padding">
        <div className='row' style={{width: '100%'}}>
          <div className="left col-lg-4">
            <a className="link" href="https://goo.gl/maps/7PPC3xJ5gy32" target="_blank" rel="noopener noreferrer">Kerkstraat 20 2570 Duffel</a>
            <a className="link" href="tel:015677143">015 67 71 43</a>
            <span>www.feststore.be</span>
            <a className="link" href="mailto:hello@feststore.be">hello@feststore.be</a>
            <span>Di-Vr / 10-18</span>
            <span>Za / 10-17</span>
          </div>
          <div className="col-lg-4 d-flex middle-wrapper">
            <div className='middle'>
              <a className="link" href="https://shop.feststore.be/policies/refund-policy" target="fest-webshop" rel="noopener noreferrer">Returns & Exchanges</a>
              <a className="link" href="https://shop.feststore.be/policies/shipping-policy" target="fest-webshop" rel="noopener noreferrer">Shipping</a>
              <a className="link" href="https://shop.feststore.be/policies/terms-of-service" target="fest-webshop" rel="noopener noreferrer">Terms & Conditions</a>
              <a className="link" href="https://shop.feststore.be/policies/privacy-policy" target="fest-webshop" rel="noopener noreferrer">Privacy policy</a>
            </div>

          </div>
          <div className="right col-lg-4">
            <Link to="/contact" className="link" title="Contact">Contact</Link>
            <span>Dufest BV</span>
            <span>BE0700 827 968</span>
            <div className="socials-wrapper">
              <a className="socials" title='Instagram' href="https://www.instagram.com/fest.store/" target="_blank" rel="noopener noreferrer">
                <Instagram />
              </a>
              <a className="socials" title="Facebook" href="https://www.facebook.com/feststore.be/" target="_blank" rel="noopener noreferrer">
                <Facebook />
              </a>    
            </div>
          </div>
        </div>
      {/* <div className="footer-bvba-info">DUFEST BV - BE0700 827 968</div> */}
      </footer>
    )
  }
}

export default Navbar
